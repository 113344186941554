
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







































































































































































$br: 0.8rem;

.cta-parallax {
  border-radius: $br;
  box-shadow: $card-shadow;

  @include mq(m) {
    display: flex;
    margin: 0;
  }
}

.cta-parallax__picture {
  position: relative;
  width: 100%;
  aspect-ratio: 3/2;
  border-radius: $br $br 0 0;

  @include mq(m) {
    width: col(5, 12);
    aspect-ratio: auto;
    border-radius: $br 0 0 $br;
  }
}

.cta-parallax__picture__main {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: $br $br 0 0;

  img {
    @include image-fit;
  }

  @include mq(m) {
    width: 90%;
    border-radius: $br 0 0 $br;
  }
}

.cta-parallax__picture__element {
  position: absolute;
  z-index: 1;
  height: fit-content;

  img {
    width: 100%;
    height: auto;
  }
}

.cta-parallax__content {
  padding: $spacing;
  background: $c-white;
  border-radius: 0 0 $br $br;

  @include mq(m) {
    flex: 1;
    padding: $spacing * 2;
    border-radius: 0 $br $br 0;
  }

  @include mq(l) {
    padding: $spacing * 5 $spacing * 5 $spacing * 4;
  }
}

.cta-parallax__content__text,
.cta-parallax__content__link {
  margin-top: $spacing;

  @include mq(m) {
    margin-top: $spacing * 2;
  }
}
